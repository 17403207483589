import React, {useState, useEffect} from 'react';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  insert_guarantee,
  get_guarantee_one,
  update_guarantee,
  get_session_data_homepage,
  get_optimum_film_type_status_1,
  get_optimum_film_type_name_status_1,
  get_guarantee_period_status_1,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import {
  removeWhitespace,
  removeWhitespacePhone,
  validatePhone,
} from '../../Validation';

export function GuaranteeAdd() {  
  const params = new URLSearchParams(window.location.search);
  const guarantee_ID_param = params.get("guarantee_ID");

  const [constructionPointIDUseGuarantee, setConstructionPointIDUseGuarantee] = useState('');
  const [guaranteeVehicleDivision, setGuaranteeVehicleDivision] = useState('1');
  const [guaranteeSerialNumber, setGuaranteeSerialNumber] = useState('');

  const [guaranteeWindshieldUseOptimumFilmTypeID, setGuaranteeWindshieldUseOptimumFilmTypeID] = useState('0');
  const [guaranteeWindshieldLotNumber, setGuaranteeWindshieldLotNumber] = useState('');
  const [guaranteeWindshieldUseGuaranteePeriodID, setGuaranteeWindshieldUseGuaranteePeriodID] = useState('0');
  const [guaranteeWindshieldUseOptimumFilmTypeName, setGuaranteeWindshieldUseOptimumFilmTypeName] = useState('');

  const [guaranteeSideGlass1UseOptimumFilmTypeID, setGuaranteeSideGlass1UseOptimumFilmTypeID] = useState('0');
  const [guaranteeSideGlass1LotNumber, setGuaranteeSideGlass1LotNumber] = useState('');
  const [guaranteeSideGlass1UseGuaranteePeriodID, setGuaranteeSideGlass1UseGuaranteePeriodID] = useState('0');
  const [guaranteeSideGlass1UseOptimumFilmTypeName, setGuaranteeSideGlass1UseOptimumFilmTypeName] = useState('');

  const [guaranteeSideGlass2UseOptimumFilmTypeID, setGuaranteeSideGlass2UseOptimumFilmTypeID] = useState('0');
  const [guaranteeSideGlass2LotNumber, setGuaranteeSideGlass2LotNumber] = useState('');
  const [guaranteeSideGlass2UseGuaranteePeriodID, setGuaranteeSideGlass2UseGuaranteePeriodID] = useState('0');
  const [guaranteeSideGlass2UseOptimumFilmTypeName, setGuaranteeSideGlass2UseOptimumFilmTypeName] = useState('');

  const [guaranteeSideGlass3UseOptimumFilmTypeID, setGuaranteeSideGlass3UseOptimumFilmTypeID] = useState('0');
  const [guaranteeSideGlass3LotNumber, setGuaranteeSideGlass3LotNumber] = useState('');
  const [guaranteeSideGlass3UseGuaranteePeriodID, setGuaranteeSideGlass3UseGuaranteePeriodID] = useState('0');
  const [guaranteeSideGlass3UseOptimumFilmTypeName, setGuaranteeSideGlass3UseOptimumFilmTypeName] = useState('');

  const [guaranteeRearGlassUseOptimumFilmTypeID, setGuaranteeRearGlassUseOptimumFilmTypeID] = useState('0');
  const [guaranteeRearGlassLotNumber, setGuaranteeRearGlassLotNumber] = useState('');
  const [guaranteeRearGlassUseGuaranteePeriodID, setGuaranteeRearGlassUseGuaranteePeriodID] = useState('0');
  const [guaranteeRearGlassUseOptimumFilmTypeName, setGuaranteeRearGlassUseOptimumFilmTypeName] = useState('');

  const [guaranteeRoofGlassUseOptimumFilmTypeID, setGuaranteeRoofGlassUseOptimumFilmTypeID] = useState('0');
  const [guaranteeRoofGlassLotNumber, setGuaranteeRoofGlassLotNumber] = useState('');
  const [guaranteeRoofGlassUseGuaranteePeriodID, setGuaranteeRoofGlassUseGuaranteePeriodID] = useState('0');
  const [guaranteeRoofGlassUseOptimumFilmTypeName, setGuaranteeRoofGlassUseOptimumFilmTypeName] = useState('');

  const [guaranteeConstructionDate, setGuaranteeConstructionDate] = useState('');
  const [guaranteeCarType, setGuaranteeCarType] = useState('');
  const [guaranteeCarNumber, setGuaranteeCarNumber] = useState(''); 
  const [guaranteeCustomerName, setGuaranteeCustomerName] = useState('');
  const [guaranteeCustomerPhoneNumber, setGuaranteeCustomerPhoneNumber] = useState('');
  const [guaranteeReview, setGuaranteeReview] = useState('');
  const [guaranteeMemo, setGuaranteeMemo] = useState('');
  const [guiaranteeConstructionLocation, setGuiaranteeConstructionLocation] = useState(''); 
  const [guiaranteeConstructionFilm, setGuiaranteeConstructionFilm] = useState(''); 


  //셀렉트 데이터
  const [optimumFilmTypeNameSelect, setOptimumFilmTypeNameSelect] = useState([]);
  const [optimumFilmTypeSelect, setOptimumFilmTypeSelect] = useState([]);
  const [guaranteePeriodSelect, setGuaranteePeriodSelect] = useState([]);

  //에러 출력 데이터
  const [guaranteeWindshieldUseOptimumFilmTypeIDError, setGuaranteeWindshieldUseOptimumFilmTypeIDError] = useState('');
  const [guaranteeSideGlass1UseOptimumFilmTypeIDrror, setGuaranteeSideGlass1UseOptimumFilmTypeIDError] = useState('');
  const [guaranteeSideGlass2UseOptimumFilmTypeIDrror, setGuaranteeSideGlass2UseOptimumFilmTypeIDError] = useState('');
  const [guaranteeSideGlass3UseOptimumFilmTypeIDrror, setGuaranteeSideGlass3UseOptimumFilmTypeIDError] = useState('');
  const [guaranteeRearGlassUseOptimumFilmTypeIDrror, setGaranteeRearGlassUseOptimumFilmTypeIDError] = useState('');
  const [guaranteeRoofGlassUseOptimumFilmTypeIDrror, setGaranteeRoofGlassUseOptimumFilmTypeIDError] = useState('');
  const [guaranteeConstructionDateError, setGuaranteeConstructionDateError] = useState('');
  const [guaranteeCarTypeError, setGuaranteeCarTypeError] = useState('');
  const [guaranteeCarNumberError, setGuaranteeCarNumberError] = useState('');
  const [guaranteeCustomerNameError, setGuaranteeCustomerNameError] = useState('');
  const [guaranteeCustomerPhoneNumberError, setGuaranteeCustomerPhoneNumberError] = useState('');
  const [guiaranteeConstructionLocationError, setGuiaranteeConstructionLocationError] = useState(''); 
  const [guiaranteeConstructionFilmError, setGuiaranteeConstructionFilmError] = useState(''); 

  //휴대폰 형식 체킹
  const validHcCustomerPhoneNumber = (value)=>{
    setGuaranteeCustomerPhoneNumber(value);
    if(!validatePhone(value)){
      setGuaranteeCustomerPhoneNumberError("휴대폰번호 형식으로 입력해주세요.");
    }else{
      setGuaranteeCustomerPhoneNumberError('');
    }
  }

  //컨트롤 함수
  const form_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(guaranteeVehicleDivision === '1'){
      if(
        guaranteeWindshieldUseOptimumFilmTypeID !== '0' && (
        guaranteeWindshieldLotNumber === '' ||
        guaranteeWindshieldUseGuaranteePeriodID === '0')
      ){setGuaranteeWindshieldUseOptimumFilmTypeIDError('전면유리 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeWindshieldUseOptimumFilmTypeIDError('');}
      if(
        guaranteeSideGlass1UseOptimumFilmTypeID !== '0' && (
        guaranteeSideGlass1LotNumber === '' ||
        guaranteeSideGlass1UseGuaranteePeriodID === '0')
      ){setGuaranteeSideGlass1UseOptimumFilmTypeIDError('측면유리1 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeSideGlass1UseOptimumFilmTypeIDError('');}
      if(
        guaranteeSideGlass2UseOptimumFilmTypeID !== '0' && (
        guaranteeSideGlass2LotNumber === '' ||
        guaranteeSideGlass2UseGuaranteePeriodID === '0')
      ){setGuaranteeSideGlass2UseOptimumFilmTypeIDError('측면유리2 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeSideGlass2UseOptimumFilmTypeIDError('');}
      if(
        guaranteeSideGlass3UseOptimumFilmTypeID !== '0' && (
        guaranteeSideGlass3LotNumber === '' ||
        guaranteeSideGlass3UseGuaranteePeriodID === '0')
      ){setGuaranteeSideGlass3UseOptimumFilmTypeIDError('측면유리3 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeSideGlass3UseOptimumFilmTypeIDError('');}
      if(
        guaranteeRearGlassUseOptimumFilmTypeID !== '0' && (
        guaranteeRearGlassLotNumber === '' ||
        guaranteeRearGlassUseGuaranteePeriodID === '0')
      ){setGaranteeRearGlassUseOptimumFilmTypeIDError('후면유리 사항을 다 입력해주세요.'); continue_add = false;}else{setGaranteeRearGlassUseOptimumFilmTypeIDError('');}
      if(
        guaranteeRoofGlassUseOptimumFilmTypeID !== '0' && (
        guaranteeRoofGlassLotNumber === '' ||
        guaranteeRoofGlassUseGuaranteePeriodID === '0')
      ){setGaranteeRoofGlassUseOptimumFilmTypeIDError('루프유리 사항을 다 입력해주세요.'); continue_add = false;}else{setGaranteeRoofGlassUseOptimumFilmTypeIDError('');}
      if(guaranteeCarType === ''){setGuaranteeCarTypeError('차종을 입력해주세요.'); continue_add = false;}else{setGuaranteeCarTypeError('');}
      if(guaranteeCarNumber === ''){setGuaranteeCarNumberError('차량번호를 입력해주세요.'); continue_add = false;}else{setGuaranteeCarNumberError('');}
    }else if(guaranteeVehicleDivision === '2' || guaranteeVehicleDivision === '3'){
      if(guiaranteeConstructionLocation === ''){setGuiaranteeConstructionLocationError('시공위치를 입력해주세요.'); continue_add = false;}else{setGuiaranteeConstructionLocationError('');}
      if(guiaranteeConstructionFilm === ''){setGuiaranteeConstructionFilmError('시공필름을 입력해주세요.'); continue_add = false;}else{setGuiaranteeConstructionFilmError('');}

    }
    if(guaranteeConstructionDate === ''){setGuaranteeConstructionDateError('시공일자를 입력해주세요.'); continue_add = false;}else{setGuaranteeConstructionDateError('');}
    if(guaranteeCustomerName === ''){setGuaranteeCustomerNameError('고객명을 입력해주세요.'); continue_add = false;}else{setGuaranteeCustomerNameError('');}
    if(!validatePhone(guaranteeCustomerPhoneNumber)){setGuaranteeCustomerPhoneNumberError("휴대폰번호 형식으로 입력해주세요."); continue_add = false;}else{setGuaranteeCustomerPhoneNumberError('');}
    if(guaranteeCustomerPhoneNumber === ''){setGuaranteeCustomerPhoneNumberError('고객전화번호를 입력해주세요.'); continue_add = false;}else{setGuaranteeCustomerPhoneNumberError('');}

    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(guarantee_ID_param === null){
        insert_guarantee(
          constructionPointIDUseGuarantee,
          guaranteeVehicleDivision,
          guaranteeWindshieldUseOptimumFilmTypeID,
          guaranteeWindshieldLotNumber,
          guaranteeWindshieldUseGuaranteePeriodID,
          guaranteeSideGlass1UseOptimumFilmTypeID,
          guaranteeSideGlass1LotNumber,
          guaranteeSideGlass1UseGuaranteePeriodID,
          guaranteeSideGlass2UseOptimumFilmTypeID,
          guaranteeSideGlass2LotNumber,
          guaranteeSideGlass2UseGuaranteePeriodID,
          guaranteeSideGlass3UseOptimumFilmTypeID,
          guaranteeSideGlass3LotNumber,
          guaranteeSideGlass3UseGuaranteePeriodID,
          guaranteeRearGlassUseOptimumFilmTypeID,
          guaranteeRearGlassLotNumber,
          guaranteeRearGlassUseGuaranteePeriodID,
          guaranteeRoofGlassUseOptimumFilmTypeID,
          guaranteeRoofGlassLotNumber,
          guaranteeRoofGlassUseGuaranteePeriodID,
          guaranteeConstructionDate,
          guaranteeCarType,
          guaranteeCarNumber,
          guaranteeCustomerName,
          guaranteeCustomerPhoneNumber,
          guaranteeReview,
          guaranteeMemo,
          guiaranteeConstructionLocation,
          guiaranteeConstructionFilm,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/Guarantee";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_guarantee(
          guarantee_ID_param,
          constructionPointIDUseGuarantee,
          guaranteeVehicleDivision,
          guaranteeWindshieldUseOptimumFilmTypeID,
          guaranteeWindshieldLotNumber,
          guaranteeWindshieldUseGuaranteePeriodID,
          guaranteeSideGlass1UseOptimumFilmTypeID,
          guaranteeSideGlass1LotNumber,
          guaranteeSideGlass1UseGuaranteePeriodID,
          guaranteeSideGlass2UseOptimumFilmTypeID,
          guaranteeSideGlass2LotNumber,
          guaranteeSideGlass2UseGuaranteePeriodID,
          guaranteeSideGlass3UseOptimumFilmTypeID,
          guaranteeSideGlass3LotNumber,
          guaranteeSideGlass3UseGuaranteePeriodID,
          guaranteeRearGlassUseOptimumFilmTypeID,
          guaranteeRearGlassLotNumber,
          guaranteeRearGlassUseGuaranteePeriodID,
          guaranteeRoofGlassUseOptimumFilmTypeID,
          guaranteeRoofGlassLotNumber,
          guaranteeRoofGlassUseGuaranteePeriodID,
          guaranteeConstructionDate,
          guaranteeCarType,
          guaranteeCarNumber,
          guaranteeCustomerName,
          guaranteeCustomerPhoneNumber,
          guaranteeReview,
          guaranteeMemo,
          guiaranteeConstructionLocation,
          guiaranteeConstructionFilm,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/Guarantee";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //백엔드 서버에 세션이 있는지 없는지 여부에 따라 로그인을 해야되는지 말아야되는지 판단하는 함수
    get_session_data_homepage().then((result)=>{
      if(result.construction_point_ID === '' || result.construction_point_ID === undefined){
        alert('로그인을 해주세요.');
        window.location.href = `/Login`;
      }else{
        let temp_optimum_film_type_array = [];
        
        //세션에 저장되어있는 시공점 ID를 저장
        setConstructionPointIDUseGuarantee(result.construction_point_ID);

        get_optimum_film_type_status_1().then((result)=>{
          temp_optimum_film_type_array = result;
          setOptimumFilmTypeSelect(result);
        });

        get_optimum_film_type_name_status_1().then((result)=>{
          setOptimumFilmTypeNameSelect(result);
        });

        //보증기간을 사용가능 한 데이터만 가져오는 API 자세한 내용은 src/Axios.js 파일 확인
        get_guarantee_period_status_1().then((result)=>{
          setGuaranteePeriodSelect(result);
        });
        
        //만약에 파라미터를 보내지 않으면 guarantee_ID값은 null(빈값으로 오기 떄문에)
        //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
        if(guarantee_ID_param !== null){
          //특정 ID의 보증서 데이터를 가져오기 위한 함수
          get_guarantee_one(guarantee_ID_param).then((result) => {
            temp_optimum_film_type_array.forEach(element =>{
              if(element.optimum_film_type_ID === result[0].guarantee_windshield_use_optimum_film_type_ID){
                setGuaranteeWindshieldUseOptimumFilmTypeName(element.optimum_film_type_name);
              }
              if(element.optimum_film_type_ID === result[0].guarantee_side_glass_1_use_optimum_film_type_ID){
                setGuaranteeSideGlass1UseOptimumFilmTypeName(element.optimum_film_type_name);
              }
              if(element.optimum_film_type_ID === result[0].guarantee_side_glass_2_use_optimum_film_type_ID){
                setGuaranteeSideGlass2UseOptimumFilmTypeName(element.optimum_film_type_name);
              }
              if(element.optimum_film_type_ID === result[0].guarantee_side_glass_3_use_optimum_film_type_ID){
                setGuaranteeSideGlass3UseOptimumFilmTypeName(element.optimum_film_type_name);
              }
              if(element.optimum_film_type_ID === result[0].guarantee_rear_glass_use_optimum_film_type_ID){
                setGuaranteeRearGlassUseOptimumFilmTypeName(element.optimum_film_type_name);
              }
              if(element.optimum_film_type_ID === result[0].guarantee_roof_glass_use_optimum_film_type_ID){
                setGuaranteeRoofGlassUseOptimumFilmTypeName(element.optimum_film_type_name);
              }
            })

            //데이터를 가져오면 데이터를 집어넣어준다.
            setGuaranteeVehicleDivision(String(result[0].guarantee_vehicle_division));
            setGuaranteeSerialNumber(result[0].guarantee_serial_number);

            setGuaranteeWindshieldUseOptimumFilmTypeID(`${result[0].guarantee_windshield_use_optimum_film_type_ID}`);
            setGuaranteeWindshieldUseGuaranteePeriodID(`${result[0].guarantee_windshield_use_guarantee_period_ID}`);
            setGuaranteeWindshieldLotNumber(result[0].guarantee_windshield_lot_number);

            setGuaranteeSideGlass1UseOptimumFilmTypeID(`${result[0].guarantee_side_glass_1_use_optimum_film_type_ID}`);
            setGuaranteeSideGlass1UseGuaranteePeriodID(`${result[0].guarantee_side_glass_1_use_guarantee_period_ID}`);
            setGuaranteeSideGlass1LotNumber(result[0].guarantee_side_glass_1_lot_number);

            setGuaranteeSideGlass2UseOptimumFilmTypeID(`${result[0].guarantee_side_glass_2_use_optimum_film_type_ID}`);
            setGuaranteeSideGlass2UseGuaranteePeriodID(`${result[0].guarantee_side_glass_2_use_guarantee_period_ID}`);
            setGuaranteeSideGlass2LotNumber(result[0].guarantee_side_glass_2_lot_number);

            setGuaranteeSideGlass3UseOptimumFilmTypeID(`${result[0].guarantee_side_glass_3_use_optimum_film_type_ID}`);
            setGuaranteeSideGlass3UseGuaranteePeriodID(`${result[0].guarantee_side_glass_3_use_guarantee_period_ID}`);
            setGuaranteeSideGlass3LotNumber(result[0].guarantee_side_glass_3_lot_number);

            setGuaranteeRearGlassUseOptimumFilmTypeID(`${result[0].guarantee_rear_glass_use_optimum_film_type_ID}`);
            setGuaranteeRearGlassUseGuaranteePeriodID(`${result[0].guarantee_rear_glass_use_guarantee_period_ID}`);
            setGuaranteeRearGlassLotNumber(result[0].guarantee_rear_glass_lot_number);

            setGuaranteeRoofGlassUseOptimumFilmTypeID(`${result[0].guarantee_roof_glass_use_optimum_film_type_ID}`);
            setGuaranteeRoofGlassUseGuaranteePeriodID(`${result[0].guarantee_roof_glass_use_guarantee_period_ID}`);
            setGuaranteeRoofGlassLotNumber(result[0].guarantee_roof_glass_lot_number);

            setGuaranteeConstructionDate(result[0].guarantee_construction_date);
            setGuaranteeCarType(result[0].guarantee_car_type);
            setGuaranteeCarNumber(result[0].guarantee_car_number);
            setGuaranteeCustomerName(result[0].guarantee_customer_name);
            setGuaranteeCustomerPhoneNumber(result[0].guarantee_customer_phone_number);
            setGuaranteeReview(result[0].guarantee_review);
            setGuaranteeMemo(result[0].guarantee_memo);
            setGuiaranteeConstructionLocation(result[0].guiarantee_construction_location);
            setGuiaranteeConstructionFilm(result[0].guiarantee_construction_film);

          })
        }
      }
    })
  },[guarantee_ID_param]);

  return (
    <> 
      <Header/>

      <H.main_sectionBox>
      <div className="main_sectionBoxInner">
        <H.printMainTitBox>
          {/* 보증서 발급 타이틀 임시로 만든거 홈페이지 맞춰서 다시 작업할것 */}
          <h1 className="printMainTit">보증서 {guarantee_ID_param === null ? ' 등록' : ' 수정'}</h1>
          <p className="printMainSubTit">* 보증서 {guarantee_ID_param === null ? ' 등록' : ' 수정'}은 PC에서만 가능합니다.</p>
        </H.printMainTitBox>

        <H.GuaranteeAddWrap>
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_serial_number'>Serial No.</label>
            <input 
              type='text' 
              id = 'guarantee_serial_number' 
              placeholder = "등록 시 자동으로 생성됩니다."
              value={guaranteeSerialNumber}
              readOnly
            />
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* 여기는 수정에서만 보임 */
            /* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_vehicle_division'>보증서 구분<span> * </span> </label>
            <select
              id='guarantee_vehicle_division'
              value={guaranteeVehicleDivision}
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuaranteeVehicleDivision(event_data.target.value);
              }}
            >
              <option value='1'>차량용</option>
              <option value='3'>PPF</option>
              <option value='2'>건축용</option>
            </select>
          </H.GuaranteeAddBox>

          {guaranteeVehicleDivision === '1' && 
          <>
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_windshield_use_optimum_film_type_ID'>전면유리</label>
            <div className='display_flex'>
              <button
                onClick={()=>{
                  setGuaranteeWindshieldUseOptimumFilmTypeID('0')
                  setGuaranteeWindshieldLotNumber('');
                  setGuaranteeWindshieldUseGuaranteePeriodID('0');
                  setGuaranteeWindshieldUseOptimumFilmTypeName('');
                }}
                className={guaranteeWindshieldUseOptimumFilmTypeName === '' ? 'film_type_button_click' : 'film_type_button_non_click'}
              >
                미선택
              </button>
              {optimumFilmTypeNameSelect.map((element, index)=>
                <button
                  key={index}
                  onClick={()=>{
                    setGuaranteeWindshieldUseOptimumFilmTypeID('0')
                    setGuaranteeWindshieldLotNumber('');
                    setGuaranteeWindshieldUseGuaranteePeriodID('0');
                    setGuaranteeWindshieldUseOptimumFilmTypeName(element.optimum_film_type_name);
                  }}
                  className={guaranteeWindshieldUseOptimumFilmTypeName === element.optimum_film_type_name ? 'film_type_button_click' : 'film_type_button_non_click'}
                >
                  {element.optimum_film_type_name}
                </button>
              )}
            </div>
            <div className="GuaranteeAddBoxMore">
              {guaranteeWindshieldUseOptimumFilmTypeName !== '' && 
                <>
                <div>
                  <p>필름종류</p>
                  <select
                    id='guarantee_windshield_use_optimum_film_type_ID'
                    value={guaranteeWindshieldUseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeWindshieldUseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeWindshieldLotNumber('');
                        setGuaranteeWindshieldUseGuaranteePeriodID('0');
                      }
                    }}
                    >
                    <option value='0'>필름을 선택해 주세요</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                    element.optimum_film_type_name === guaranteeWindshieldUseOptimumFilmTypeName &&
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                <div>
                  <p>Lot 번호</p>
                  <input 
                    type='text' 
                    id = 'guarantee_windshield_lot_number' 
                    maxLength={100} 
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeWindshieldLotNumber(event_data.target.value);
                    }} 
                    placeholder = "Lot번호를 입력해 주세요."
                    value={guaranteeWindshieldLotNumber}
                  />
                </div>
                <div>
                  <p>보증기간</p>
                  <select
                    id='guarantee_windshield_use_guarantee_period_ID'
                    value={guaranteeWindshieldUseGuaranteePeriodID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeWindshieldUseGuaranteePeriodID(event_data.target.value);
                    }}
                    >
                    <option value='0'>보증기간을 선택해 주세요</option>
                    {guaranteePeriodSelect.map((element, index) => 
                      <option value={element.guarantee_period_ID} key={index}>
                        {element.guarantee_period_year}
                      </option>
                    )}
                  </select>
                </div>
              </>
              }
            </div>
            <H.admin_errorBox>{guaranteeWindshieldUseOptimumFilmTypeIDError}</H.admin_errorBox>
          </H.GuaranteeAddBox>
            
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_side_glass_1_use_optimum_film_type_ID'>측면유리1</label>
            <div className='display_flex'>
              <button
                onClick={()=>{
                  setGuaranteeSideGlass1UseOptimumFilmTypeID('0')
                  setGuaranteeSideGlass1LotNumber('');
                  setGuaranteeSideGlass1UseGuaranteePeriodID('0');
                  setGuaranteeSideGlass1UseOptimumFilmTypeName('');
                }}
                className={guaranteeSideGlass1UseOptimumFilmTypeName === '' ? 'film_type_button_click' : 'film_type_button_non_click'}
              >
                미선택
              </button>
              {optimumFilmTypeNameSelect.map((element, index)=>
                <button
                  key={index}
                  onClick={()=>{
                    setGuaranteeSideGlass1UseOptimumFilmTypeID('0')
                    setGuaranteeSideGlass1LotNumber('');
                    setGuaranteeSideGlass1UseGuaranteePeriodID('0');
                    setGuaranteeSideGlass1UseOptimumFilmTypeName(element.optimum_film_type_name);
                  }}
                  className={guaranteeSideGlass1UseOptimumFilmTypeName === element.optimum_film_type_name ? 'film_type_button_click' : 'film_type_button_non_click'}
                >
                  {element.optimum_film_type_name}
                </button>
              )}
            </div>
            <div className="GuaranteeAddBoxMore">
              {guaranteeSideGlass1UseOptimumFilmTypeName !== '' && 
                <>
                <div>
                  <p>필름종류</p>
                  <select
                    id='guarantee_side_glass_1_use_optimum_film_type_ID'
                    value={guaranteeSideGlass1UseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass1UseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeSideGlass1LotNumber('');
                        setGuaranteeSideGlass1UseGuaranteePeriodID('0');
                      }
                    }}
                  >
                    <option value='0'>필름을 선택해 주세요</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      element.optimum_film_type_name === guaranteeSideGlass1UseOptimumFilmTypeName &&
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                <div>
                  <p>Lot 번호</p>
                  <input 
                    type='text' 
                    id = 'guarantee_side_glass_1_lot_number' 
                    maxLength={100} 
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass1LotNumber(event_data.target.value);
                    }} 
                    placeholder = "Lot번호를 입력해 주세요."
                    value={guaranteeSideGlass1LotNumber}
                  />
                </div>
                <div>
                  <p>보증기간</p>
                  <select
                    id='guarantee_side_glass_1_use_guarantee_period_ID'
                    value={guaranteeSideGlass1UseGuaranteePeriodID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass1UseGuaranteePeriodID(event_data.target.value);
                    }}
                    >
                    <option value='0'>보증기간을 선택해 주세요</option>
                    {guaranteePeriodSelect.map((element, index) => 
                      <option value={element.guarantee_period_ID} key={index}>
                        {element.guarantee_period_year}
                      </option>
                    )}
                  </select>
                </div>
              </>
              }
            </div>
            <H.admin_errorBox>{guaranteeSideGlass1UseOptimumFilmTypeIDrror}</H.admin_errorBox>
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_side_glass_2_use_optimum_film_type_ID'>측면유리2</label>
            <div className='display_flex'>
              <button
                onClick={()=>{
                  setGuaranteeSideGlass2UseOptimumFilmTypeID('0')
                  setGuaranteeSideGlass2LotNumber('');
                  setGuaranteeSideGlass2UseGuaranteePeriodID('0');
                  setGuaranteeSideGlass2UseOptimumFilmTypeName('');
                }}
                className={guaranteeSideGlass2UseOptimumFilmTypeName === '' ? 'film_type_button_click' : 'film_type_button_non_click'}
              >
                미선택
              </button>
              {optimumFilmTypeNameSelect.map((element, index)=>
                <button
                  key={index}
                  onClick={()=>{
                    setGuaranteeSideGlass2UseOptimumFilmTypeID('0')
                    setGuaranteeSideGlass2LotNumber('');
                    setGuaranteeSideGlass2UseGuaranteePeriodID('0');
                    setGuaranteeSideGlass2UseOptimumFilmTypeName(element.optimum_film_type_name);
                  }}
                  className={guaranteeSideGlass2UseOptimumFilmTypeName === element.optimum_film_type_name ? 'film_type_button_click' : 'film_type_button_non_click'}
                >
                  {element.optimum_film_type_name}
                </button>
              )}
            </div>

            <div className="GuaranteeAddBoxMore">
              {guaranteeSideGlass2UseOptimumFilmTypeName !== '' && 
                <>
                <div>
                  <p>필름종류</p>
                  <select
                    id='guarantee_side_glass_2_use_optimum_film_type_ID'
                    value={guaranteeSideGlass2UseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass2UseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeSideGlass2LotNumber('');
                        setGuaranteeSideGlass2UseGuaranteePeriodID('0');
                      }
                    }}
                    >
                    <option value='0'>필름을 선택해 주세요</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      element.optimum_film_type_name === guaranteeSideGlass2UseOptimumFilmTypeName &&
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                <div>
                  <p>Lot 번호</p>
                  <input 
                    type='text' 
                    id = 'guarantee_side_glass_2_lot_number' 
                    maxLength={100} 
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass2LotNumber(event_data.target.value);
                    }} 
                    placeholder = "Lot번호를 입력해 주세요."
                    value={guaranteeSideGlass2LotNumber}
                  />
                </div>
                <div>
                  <p>보증기간</p>
                  <select
                    id='guarantee_side_glass_2_use_guarantee_period_ID'
                    value={guaranteeSideGlass2UseGuaranteePeriodID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass2UseGuaranteePeriodID(event_data.target.value);
                    }}
                  >
                    <option value='0'>보증기간을 선택해 주세요</option>
                    {guaranteePeriodSelect.map((element, index) => 
                      <option value={element.guarantee_period_ID} key={index}>
                        {element.guarantee_period_year}
                      </option>
                    )}
                  </select>
                </div>
                </>
              }
            </div>
            <H.admin_errorBox>{guaranteeSideGlass2UseOptimumFilmTypeIDrror}</H.admin_errorBox>
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
             {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
             <label htmlFor='guarantee_side_glass_3_use_optimum_film_type_ID'>측면유리3</label>
             <div className='display_flex'>
              <button
                onClick={()=>{
                  setGuaranteeSideGlass3UseOptimumFilmTypeID('0')
                  setGuaranteeSideGlass3LotNumber('');
                  setGuaranteeSideGlass3UseGuaranteePeriodID('0');
                  setGuaranteeSideGlass3UseOptimumFilmTypeName('');
                }}
                className={guaranteeSideGlass3UseOptimumFilmTypeName === '' ? 'film_type_button_click' : 'film_type_button_non_click'}
              >
                미선택
              </button>
              {optimumFilmTypeNameSelect.map((element, index)=>
                <button
                  key={index}
                  onClick={()=>{
                    setGuaranteeSideGlass3UseOptimumFilmTypeID('0')
                    setGuaranteeSideGlass3LotNumber('');
                    setGuaranteeSideGlass3UseGuaranteePeriodID('0');
                    setGuaranteeSideGlass3UseOptimumFilmTypeName(element.optimum_film_type_name);
                  }}
                  className={guaranteeSideGlass3UseOptimumFilmTypeName === element.optimum_film_type_name ? 'film_type_button_click' : 'film_type_button_non_click'}
                >
                  {element.optimum_film_type_name}
                </button>
              )}
            </div>
              <div className="GuaranteeAddBoxMore">
                {guaranteeSideGlass3UseOptimumFilmTypeName !== '' && 
                  <>
                  <div>
                    <p>필름종류</p>
                    <select
                      id='guarantee_side_glass_3_use_optimum_film_type_ID'
                      value={guaranteeSideGlass3UseOptimumFilmTypeID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass3UseOptimumFilmTypeID(event_data.target.value);
                        if(event_data.target.value === '0'){
                          setGuaranteeSideGlass3LotNumber('');
                          setGuaranteeSideGlass3UseGuaranteePeriodID('0');
                        }
                      }}
                      >
                      <option value='0'>필름을 선택해 주세요</option>
                      {optimumFilmTypeSelect.map((element, index) => 
                        element.optimum_film_type_name === guaranteeSideGlass3UseOptimumFilmTypeName &&
                        <option value={element.optimum_film_type_ID} key={index}>
                          {element.optimum_film_type_name} {element.optimum_film_type_density}
                        </option>
                      )}
                    </select>
                  </div>
                  <div>
                    <p>Lot 번호</p>
                    <input 
                      type='text' 
                      id = 'guarantee_side_glass_3_lot_number' 
                      maxLength={100} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass3LotNumber(event_data.target.value);
                      }} 
                      placeholder = "Lot번호를 입력해 주세요."
                      value={guaranteeSideGlass3LotNumber}
                    />
                  </div>
                  <div>
                    <p>보증기간</p>
                    <select
                      id='guarantee_side_glass_3_use_guarantee_period_ID'
                      value={guaranteeSideGlass3UseGuaranteePeriodID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass3UseGuaranteePeriodID(event_data.target.value);
                      }}
                    >
                      <option value='0'>보증기간을 선택해 주세요</option>
                      {guaranteePeriodSelect.map((element, index) => 
                        <option value={element.guarantee_period_ID} key={index}>
                          {element.guarantee_period_year}
                        </option>
                      )}
                    </select>
                  </div>
                  </>
                }
            </div>
            <H.admin_errorBox>{guaranteeSideGlass3UseOptimumFilmTypeIDrror}</H.admin_errorBox>
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_rear_glass_use_optimum_film_type_ID'>후면유리</label>  
            <div className='display_flex'>
              <button
                onClick={()=>{
                  setGuaranteeRearGlassUseOptimumFilmTypeID('0')
                  setGuaranteeRearGlassLotNumber('');
                  setGuaranteeRearGlassUseGuaranteePeriodID('0');
                  setGuaranteeRearGlassUseOptimumFilmTypeName('');
                }}
                className={guaranteeRearGlassUseOptimumFilmTypeName === '' ? 'film_type_button_click' : 'film_type_button_non_click'}
              >
                미선택
              </button>
              {optimumFilmTypeNameSelect.map((element, index)=>
                <button
                  key={index}
                  onClick={()=>{
                    setGuaranteeRearGlassUseOptimumFilmTypeID('0')
                    setGuaranteeRearGlassLotNumber('');
                    setGuaranteeRearGlassUseGuaranteePeriodID('0');
                    setGuaranteeRearGlassUseOptimumFilmTypeName(element.optimum_film_type_name);

                  }}
                  className={guaranteeRearGlassUseOptimumFilmTypeName === element.optimum_film_type_name ? 'film_type_button_click' : 'film_type_button_non_click'}
                >
                  {element.optimum_film_type_name}
                </button>
              )}
            </div>

            <div className="GuaranteeAddBoxMore">
              {guaranteeRearGlassUseOptimumFilmTypeName !== '' && 
                <>
                <div>
                  <p>필름종류</p>
                  <select
                    id='guarantee_rear_glass_use_optimum_film_type_ID'
                    value={guaranteeRearGlassUseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRearGlassUseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeRearGlassLotNumber('');
                        setGuaranteeRearGlassUseGuaranteePeriodID('0');
                      }
                    }}
                    >
                    <option value='0'>필름을 선택해 주세요</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      element.optimum_film_type_name === guaranteeRearGlassUseOptimumFilmTypeName &&
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                <div>
                  <p>Lot 번호</p>
                  <input 
                    type='text' 
                    id = 'guarantee_rear_glass_lot_number' 
                    maxLength={100} 
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRearGlassLotNumber(event_data.target.value);
                    }} 
                    placeholder = "Lot번호를 입력해 주세요."
                    value={guaranteeRearGlassLotNumber}
                  />
                </div>
                <div>
                  <p>보증기간</p>
                  <select
                    id='guarantee_rear_glass_use_guarantee_period_ID'
                    value={guaranteeRearGlassUseGuaranteePeriodID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRearGlassUseGuaranteePeriodID(event_data.target.value);
                    }}
                    >
                    <option value='0'>보증기간을 선택해 주세요</option>
                    {guaranteePeriodSelect.map((element, index) => 
                      <option value={element.guarantee_period_ID} key={index}>
                        {element.guarantee_period_year}
                      </option>
                    )}
                  </select>
                </div>
                </>
              }
            </div>
            <H.admin_errorBox>{guaranteeRearGlassUseOptimumFilmTypeIDrror}</H.admin_errorBox>
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_roof_glass_use_optimum_film_type_ID'>루프유리</label>
            <div className='display_flex'>
              <button
                onClick={()=>{
                  setGuaranteeRoofGlassUseOptimumFilmTypeID('0')
                  setGuaranteeRoofGlassLotNumber('');
                  setGuaranteeRoofGlassUseGuaranteePeriodID('0');
                  setGuaranteeRoofGlassUseOptimumFilmTypeName('');
                }}
                className={guaranteeRoofGlassUseOptimumFilmTypeName === '' ? 'film_type_button_click' : 'film_type_button_non_click'}
              >
                미선택
              </button>
              {optimumFilmTypeNameSelect.map((element, index)=>
                <button
                  key={index}
                  onClick={()=>{
                    setGuaranteeRoofGlassUseOptimumFilmTypeID('0')
                    setGuaranteeRoofGlassLotNumber('');
                    setGuaranteeRoofGlassUseGuaranteePeriodID('0');
                    setGuaranteeRoofGlassUseOptimumFilmTypeName(element.optimum_film_type_name);
                  }}
                  className={guaranteeRoofGlassUseOptimumFilmTypeName === element.optimum_film_type_name ? 'film_type_button_click' : 'film_type_button_non_click'}
                >
                  {element.optimum_film_type_name}
                </button>
              )}
            </div>

            <div className="GuaranteeAddBoxMore">
              {guaranteeRoofGlassUseOptimumFilmTypeName !== '' && 
                <>
                <div>
                  <p>필름종류</p>
                  <select
                    id='guarantee_roof_glass_use_optimum_film_type_ID'
                    value={guaranteeRoofGlassUseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRoofGlassUseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeRoofGlassLotNumber('');
                        setGuaranteeRoofGlassUseGuaranteePeriodID('0');
                      }
                    }}
                    >
                    <option value='0'>필름을 선택해 주세요</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                    element.optimum_film_type_name === guaranteeRoofGlassUseOptimumFilmTypeName &&
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                <div>
                  <p>Lot 번호</p>
                  <input 
                    type='text' 
                    id = 'guarantee_roof_glass_lot_number' 
                    maxLength={100} 
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRoofGlassLotNumber(event_data.target.value);
                    }} 
                    placeholder = "Lot번호를 입력해 주세요."
                    value={guaranteeRoofGlassLotNumber}
                  />
                </div>
                <div>
                  <p>보증기간</p>
                  <select
                    id='guarantee_roof_glass_use_guarantee_period_ID'
                    value={guaranteeRoofGlassUseGuaranteePeriodID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRoofGlassUseGuaranteePeriodID(event_data.target.value);
                    }}
                    >
                    <option value='0'>보증기간을 선택해 주세요</option>
                    {guaranteePeriodSelect.map((element, index) => 
                      <option value={element.guarantee_period_ID} key={index}>
                        {element.guarantee_period_year}
                      </option>
                    )}
                  </select>
                </div>
              </>
              }
            </div>
            <H.admin_errorBox>{guaranteeRoofGlassUseOptimumFilmTypeIDrror}</H.admin_errorBox>
          </H.GuaranteeAddBox>
          </>
          }
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_construction_date'>시공일자<span> * </span></label>
            <input 
                className="GuaranteeAddDate"
                type='date' 
                id = 'guarantee_construction_date' 
                max = '2999-01-01'
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setGuaranteeConstructionDate(event_data.target.value);
                }} 
                value={guaranteeConstructionDate}
              />
            <H.admin_errorBox>{guaranteeConstructionDateError}</H.admin_errorBox>
          </H.GuaranteeAddBox>

          {guaranteeVehicleDivision === '1' && 
          <>
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_car_type'>차종<span> * </span></label>
            <input 
              type='text' 
              id = 'guarantee_car_type' 
              maxLength={50} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuaranteeCarType(event_data.target.value);
              }} 
              placeholder = "차종을 입력해주세요."
              value={guaranteeCarType}
            />
            <H.admin_errorBox>{guaranteeCarTypeError}</H.admin_errorBox>
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_car_number'>차량번호<span> * </span></label>
            <input 
              type='text' 
              id = 'guarantee_car_number' 
              maxLength={50} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuaranteeCarNumber(removeWhitespace(event_data.target.value));
              }} 
              placeholder = "차량번호를 입력해 주세요."
              value={guaranteeCarNumber}
            />
            <H.admin_errorBox>{guaranteeCarNumberError}</H.admin_errorBox>
          </H.GuaranteeAddBox>
          </>
          }
          {(guaranteeVehicleDivision === '2'  || guaranteeVehicleDivision === '3') && 
          <>
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guiarantee_construction_location'>시공위치<span> * </span></label>
            <input 
              type='text' 
              id = 'guiarantee_construction_location' 
              maxLength={250} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuiaranteeConstructionLocation(event_data.target.value);
              }} 
              placeholder = "시공위치를 입력해 주세요."
              value={guiaranteeConstructionLocation}
            />
            <H.admin_errorBox>{guiaranteeConstructionLocationError}</H.admin_errorBox>
          </H.GuaranteeAddBox>
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guiarantee_construction_film'>시공필름<span> * </span></label>
            <input 
              type='text' 
              id = 'guiarantee_construction_film' 
              maxLength={50} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuiaranteeConstructionFilm(event_data.target.value);
              }} 
              placeholder = "시공필름을 입력해 주세요."
              value={guiaranteeConstructionFilm}
            />
            <H.admin_errorBox>{guiaranteeConstructionFilmError}</H.admin_errorBox>
          </H.GuaranteeAddBox>
          </>
          }
          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_customer_name'>고객명<span> * </span></label>
            <input 
              type='text' 
              id = 'guarantee_customer_name' 
              maxLength={20} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuaranteeCustomerName(event_data.target.value);
              }} 
              placeholder = "고객명를 입력해 주세요."
              value={guaranteeCustomerName}
            />
            <H.admin_errorBox>{guaranteeCustomerNameError}</H.admin_errorBox> 
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_customer_phone_number'>고객 전화번호<span> * </span> </label>
            <input 
              type='text' 
              id = 'guarantee_customer_phone_number' 
              maxLength={20} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                validHcCustomerPhoneNumber(removeWhitespacePhone(event_data.target.value));
              }} 
              placeholder = "고객전화번호를 입력해 주세요."
              value={guaranteeCustomerPhoneNumber}
            />
            <H.admin_errorBox>{guaranteeCustomerPhoneNumberError}</H.admin_errorBox>
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_review'>후기링크</label>
            <input 
              type='text' 
              id = 'guarantee_review' 
              maxLength={200} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuaranteeReview(event_data.target.value);
              }} 
              placeholder = "후기링크를 입력해 주세요."
              value={guaranteeReview}
            />
          </H.GuaranteeAddBox>

          <H.GuaranteeAddBox>
            {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
            <label htmlFor='guarantee_memo'>메모</label>
            <input 
              type='text' 
              id = 'guarantee_memo' 
              maxLength={200} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setGuaranteeMemo(event_data.target.value);
              }} 
              placeholder = "기타메모를 입력해 주세요."
              value={guaranteeMemo}
            />
          </H.GuaranteeAddBox>

          <button
            //onClick event에 대한 내용은 src/Admin/Login.js 참고
            onClick={()=>{form_check(); }}>
            {/* 보증서 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
            {guarantee_ID_param === null ? ' 등록 ' : ' 수정 '}
          </button>

        </H.GuaranteeAddWrap>

        </div>
      </H.main_sectionBox>
      <Footer/>
    </>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default GuaranteeAdd;
