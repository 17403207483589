import React, {useState} from 'react';
import {login_judgement} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import admin_loginImg from '../../Images/optimum_admin_loginImg.png';
import admin_logowhite from '../../Images/autobahn_korea_logo_w.png';


function AdminLogin() {
  const [userLoginID, setUserLoginID] = useState('');
  const [userPassword, setUserPassword] = useState('');

  //로그인 버튼을 클릭 했을 시 저장된 아이디인지 아닌지를 판단하는 Function
  const check_login = () => {
    //login_judgement Function은 Axios.js 파일 안에 있는 Function이며 자세한 내용은 해당폴더에 적어났음.
    //login_judgement Function에 매개변수 userLoginID와, userPassword를 보내서 return을 받아오는데 
    //그 리턴값을 result라고 명명하고 그 result라는 값에 따라 로그인을 정상적으로 했는지 안했는지 판단함
    login_judgement(userLoginID, userPassword).then((result)=>{
        //result === 0 이라는 것은 아이디 및 비밀번호를 체킹하여 없는 값일 경우 백엔드에서 0을 반환하는데 그럴 경우 알람창을 뛰웁니다.
        if(result === 0){
            alert('아이디 및 비밀번호를 확인해주세요.');
        //만약에 result가 0이 아닐경우에는 window.location.href를 통해 라우터 이동을 함
        //라우터 정보는 index.js 파일에 적어났음.
        }else{
            window.location.href = "/AdminMain";
        }
    });
  }

  //return이라고 함은 HTML을 뿌려주기 위한 장소라고 생각하시면 됩니다.
  //return이전에는 자바스크립트를 처리하는 공간이고 그 자바스크립트를 처리한 내용을 바탕으로 HTML이 뿌려지는 구조가 React 구조입니다.
  //사실 return이라고 함은 함수가 처리되고 나서 마지막으로 값을 반환시킬 것이 있다면 return이라고 쓰는데
  //여기서는 function Login() { 의 return값이 아래 HTML이라고 생각하시면 됩니다.
  //Axios.js가 보면 어떠어떠한 처리를 하고 꼭 나중에는 DB에서 받아온 값을 return을 써서 보내주거든요.
  //위의 login_judgement도 보면 userLoingID와 userPassword를 매개변수로하여 처리한 후에 result의 값으로 로그인을 했는지
  //안했는지 판단하는데 result라는 것이 사실 함수에서 return으로 나온 값을 result로 사용한다는 겁니다.
  return (

    <H.admin_LoginWrap admin_loginImg = {admin_loginImg}>
      <img src = {admin_loginImg} alt="배경이미지"></img>
      <H.admin_LoginInner>
        <H.admin_LoginLogo admin_logowhite = {admin_logowhite}></H.admin_LoginLogo>
        <H.admin_LoginFormBox>
          <H.admin_LoginTit>관리자 로그인</H.admin_LoginTit>
          <H.admin_LoginInputBox>
            {/* htmlFor는 id와 연동되는 것인데 
            예를들어 아래 로그인 input을 보면 id를 user_login_ID라 해났으며
            label을 클릭하면 user_login_ID를 클릭하는 것과 동일한 현상이 일어남*/}
            <label htmlFor='user_login_ID'>아이디</label>
            <input 
              type='text' 
              id = 'user_login_ID' 
              maxLength={50} 
              onChange={(event_data)=>{
                setUserLoginID(event_data.target.value);
              }}
              onKeyDown={(event_data)=>{
                if (event_data.key === 'Enter') {
                  check_login();
                }
              }}
              placeholder = "아이디를 입력해 주세요."
            />
          </H.admin_LoginInputBox>
          <H.admin_LoginInputBox>
            <label htmlFor='user_password'>비밀번호</label>
            <input 
              type='password' 
              id = 'user_password' 
              maxLength={50}
              onChange={(event_data)=>{
                setUserPassword(event_data.target.value);
              }}
              onKeyDown={(event_data)=>{
                if (event_data.key === 'Enter') {
                  check_login();
                }
              }}
              placeholder = "비밀번호를 입력해 주세요."
              />
          </H.admin_LoginInputBox>
  
            {/* onClick이란 event라는 것인데 event에 대한 내용은 위에 참조하고
            onClick이벤트는 클릭했을 시 이벤트임 */}
          <H.admin_LoginBtn onClick={()=>{
              //버튼을 클릭했을 시 위에 있는 check_login 함수를 호출
              check_login();
              }}>
              로그인
          </H.admin_LoginBtn>
          <H.admin_LoginSearch>
            {/* 아이디 및 비밀번호 찾기는 나중에 할 예정 */}
            <button>아이디 찾기</button>
            <span></span>
            <button>비밀번호 찾기</button>
          </H.admin_LoginSearch>
        </H.admin_LoginFormBox>
      </H.admin_LoginInner>
    </H.admin_LoginWrap>


  );
}

export default AdminLogin;