//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
//이거는 tabulator라는 게시판을 쉽게 만들기 위한 패키지이고
//https://tabulator.info/ <-이게 공식 홈페이지입니다.
//저는 게시판을 만들때 Tabulator를 많이사용합니다.
//설치는 npm install react-tabulator 로 설치했고요 :)
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';
//아래 함수들에 대한 자세한 설명은 Axios.js 파일을 참고하세요
import {
  get_guarantee,
  get_guarantee_management_search,
} from "../../Axios";
import detail_icon from '../../Images/Etc/detail_icon.png';

export function GuaranteeManagement() {  

  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [guaranteeData, setGuaranteeData] = useState([]); //백엔드에서 가져온 보증기간 데이터를 담기위한 useState

  //검색 데이터
  const [constructionPointNameSearch, setConstructionPointNameSearch] = useState('');
  const [guaranteeCustomerNameSearch, setGuaranteeCustomerNameSearch] = useState('');

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // tabulatorRef이거는 아래 <ReactTabulator> 이거를  참고하기 위해 사용했으며
  // <ReactTabulator> 가면 tabulatorRef를 사용한것을 볼 수 있습니다.
  const tabulatorRef = useRef();

    // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
    useEffect(() => {
      //이거는 데이터베이스에 있는 보증기간들을 가져오기 위한 Function이고 
      //자세한 내용은 src/Axios.js 파일 확인
      get_guarantee().then(result_data=>{
        //보증기간 데이터를 setGuaranteeData를 활용하여 guaranteeData에 할당
        setGuaranteeData(result_data);
        //만약에 보증기간 데이터를 어떻게 가져왔나를 확인하고 싶으면
        //아래 console.log(result_data)주석을 풀어서
        //크롬창에 F12누르면 콘솔에 찍힙니다.
        /* console.log(result_data); */
      })

    },[]);


  //컨트롤 함수
  const form_check = () =>{
    get_guarantee_management_search(
      constructionPointNameSearch,
      guaranteeCustomerNameSearch,
    ).then((result)=>{
      if(result.length === 0){
        alert('검색된 데이터가 없습니다. 시공점에 확인해주세요.');
      }else{
        setGuaranteeData(result);
      }
    });
  }

  // 테이블에 사용하는 아이콘
  const detailIcon = function(){
    return `
<img 
  src=${detail_icon} 
  alt="디테일 이미지"
  style="width:15px;heihgt:15px"
/>`
  };
  
  const set_film_colums = (cell)=>{
    let temp_construction_product_print = '';

    if(cell.getRow().getData().guarantee_vehicle_division === 1){
      if(cell.getRow().getData().guarantee_windshield_use_optimum_film_type_ID !== 0){
        temp_construction_product_print = temp_construction_product_print + '전면('+cell.getRow().getData().guarantee_windshield_use_optimum_film_type_name+'), ';
      }
      if(cell.getRow().getData().guarantee_side_glass_1_use_optimum_film_type_ID !== 0){
        temp_construction_product_print = temp_construction_product_print + '측면1('+cell.getRow().getData().guarantee_side_glass_1_use_optimum_film_type_name+'), ';
      }
      if(cell.getRow().getData().guarantee_side_glass_2_use_optimum_film_type_ID !== 0){
        temp_construction_product_print = temp_construction_product_print + '측면2('+cell.getRow().getData().guarantee_side_glass_2_use_optimum_film_type_name+'), ';
      }
      if(cell.getRow().getData().guarantee_side_glass_3_use_optimum_film_type_ID !== 0){
        temp_construction_product_print = temp_construction_product_print + '측면3('+cell.getRow().getData().guarantee_side_glass_3_use_optimum_film_type_name+'), ';
      }
      if(cell.getRow().getData().guarantee_rear_glass_use_optimum_film_type_ID !== 0){
        temp_construction_product_print = temp_construction_product_print + '후면('+cell.getRow().getData().guarantee_rear_glass_use_optimum_film_type_name+'), ';
      }
      if(cell.getRow().getData().guarantee_roof_glass_use_optimum_film_type_ID !== 0){
        temp_construction_product_print = temp_construction_product_print + '루프('+cell.getRow().getData().guarantee_roof_glass_use_optimum_film_type_name+'), ';
      }     
    }else{ 
      temp_construction_product_print = cell.getRow().getData().guiarantee_construction_film
    }
    return temp_construction_product_print;
  }
  
  return (
    <H.admin_MainWrap> {/* 전체박스 */}
      <AdminHeader/> {/* 헤더 */}
      <H.admin_rightWrap> {/* 우측 전체박스*/}
        <H.admin_rightTitBox>{/* 우측 박스 */}
          <H.admin_rightTitInner>{/* 우측 이너이너 */}
            <p>보증서 관리</p>
            <div>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_topSearch>
          <div className="admin_topSearchInner">
            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='guarantee_customer_name'>시공점명</label>
              <input 
                type='text' 
                id = 'guarantee_customer_name' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointNameSearch(event_data.target.value);
                }} 
                placeholder = "시공점명을 입력해 주세요."
                value={constructionPointNameSearch}
              />
            </div>
          
            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='guarantee_customer_phone_number'>고객명</label>
              <input 
                type='text' 
                id = 'guarantee_customer_phone_number' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setGuaranteeCustomerNameSearch(event_data.target.value);
                }} 
                placeholder = "고객명을 입력해 주세요."
                value={guaranteeCustomerNameSearch}
              />
            </div>
            <button
              //onClick event에 대한 내용은 src/Admin/Login.js 참고
              onClick={()=>{form_check(); }}>
              {/* 보증서 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
              검색
            </button>
          </div>
        </H.admin_topSearch>
          
        <H.admin_rightInfoBox>{/* 우측 아래 */}
          <H.admin_rightInfoInner>
            {
            guaranteeData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={guaranteeData}
              //밑에 있는 colums는 tabulator에서 제공하는 형식에 맞춰서 컬럼들을 정의하는 겁니다.
              //자세한 내용은 https://tabulator.info/ 공식홈페이지 참고하시면 됩니다.
              columns={[
                { title: "ID", field: "guarantee_ID", headerSort:false},
                { title: "상세보기", formatter:detailIcon, hozAlign:"center", headerSort:false,
                cellClick:function(e, cell){
                  window.location.href = `/GuaranteeManagementDetail?guarantee_ID=${cell.getRow().getData().guarantee_ID}`;
                }},
                { title: "시공점명", field: "construction_point_name", headerSort:false},
                { title: "시공 일자", field: "guarantee_construction_date", headerSort:false},
                { title: "보증서 구분 숫자", field: "guarantee_vehicle_division", visible:false},
                { title: "보증서 구분", field: "guarantee_vehicle_division_name", headerSort:false},
                { title: "차종", field: "guarantee_car_type", headerSort:false},
                { title: "차량번호", field: "guarantee_car_number", headerSort:false},
                { title: "고객명", field: "guarantee_customer_name", headerSort:false},
                { title: "고객전화번호", field: "guarantee_customer_phone_number", headerSort:false},
                { title: "시공필름", headerSort:false, formatter:set_film_colums},
                { title: "시공위치", field: "guiarantee_construction_location", headerSort:false},
                { title: "시공필름(건축용)", field: "guiarantee_construction_film", visible:false},
                { title: "후기등록", field: "guarantee_review", headerSort:false},
                { title: "메모", field: "guarantee_memo", headerSort:false},
                { title: "등록 일자", field: "guarantee_registe", headerSort:false},
                { title: "수정 일자", field: "guarantee_modified", headerSort:false},     
              ]}
              options={{ 
                paginationSize:30,
                pagination:'local',
                paginationButtonCount:5, // 밑에 표시될 페이지 갯수
              }}
              layout={"fitData"}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default GuaranteeManagement;
