//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState} from 'react';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {login_judgement_construction_point} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

function Login() {
  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  const [constructionPointLoginID, setconstructionPointLoginID] = useState('');
  const [constructionPointPassword, setconstructionPointPassword] = useState('');

  //로그인 버튼을 클릭 했을 시 저장된 아이디인지 아닌지를 판단하는 Function
  const check_login = () => {
    if(constructionPointLoginID === '' || constructionPointPassword === '' ){
      alert('아이디 및 비밀번호를 입력해주세요.');
    }else{
      //login_judgement_construction_point Function은 Axios.js 파일 안에 있는 Function이며 자세한 내용은 해당폴더에 적어났음.
      //login_judgement_construction_point Function에 매개변수 constructionPointLoginID와, constructionPointPassword를 보내서 return을 받아오는데 
      //그 리턴값을 result라고 명명하고 그 result라는 값에 따라 로그인을 정상적으로 했는지 안했는지 판단함
      login_judgement_construction_point(constructionPointLoginID, constructionPointPassword).then((result)=>{
          //result === 0 이라는 것은 아이디 및 비밀번호를 체킹하여 없는 값일 경우 백엔드에서 0을 반환하는데 그럴 경우 알람창을 뛰웁니다.
          if(result.result_number === '-1'){
            alert('아이디 및 비밀번호를 확인해주세요.');
            //만약에 result가 0이 아닐경우에는 window.location.href를 통해 라우터 이동을 함
            //라우터 정보는 index.js 파일에 적어났음.
          }else if(result.result_number === '0'){
            window.location.href = "/Guarantee";
          }else if(result.result_number === '1'){
            window.location.href = `/NoticeDetail?notice_ID=${result.notice_ID}`;
          }else{
            window.location.href = "/";
          }
      });
    }
  }

  return (
    <>
      {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
      <Header/>

      <H.main_sectionBox>
        <div className="main_sectionBoxInner">
          <H.main_loginWrap>
            <div className="main_loginTit">
              <h2>
                로그인
              </h2>
              <p>
                * 보증서 발급은 PC에서만 가능합니다.
              </p>
            </div>
            
            <div className="main_loginBox">
              {/* htmlFor는 id와 연동되는 것인데 
              예를들어 아래 로그인 input을 보면 id를 construction_point_login_ID라 해났으며
              label을 클릭하면 construction_point_login_ID를 클릭하는 것과 동일한 현상이 일어남*/}
              <label htmlFor='construction_point_login_ID'>아이디</label>
              <input 
                type='text' 
                id = 'construction_point_login_ID' 
                maxLength={50}
                onChange={(event_data)=>{
                  setconstructionPointLoginID(event_data.target.value);
                }}
                onKeyDown={(event_data)=>{
                  if (event_data.key === 'Enter') {
                    check_login();
                  }
                }}
                placeholder = "아이디를 입력해 주세요."
              />
            </div>
            <div className="main_loginBox">
              <label htmlFor='construction_point_password'>비밀번호</label>
              <input 
                type='password' 
                id = 'construction_point_password' 
                maxLength={50}
                onChange={(event_data)=>{
                  setconstructionPointPassword(event_data.target.value);
                }}
                onKeyDown={(event_data)=>{
                  if (event_data.key === 'Enter') {
                    check_login();
                  }
                }}
                placeholder = "비밀번호를 입력해 주세요."
                />
            </div>

            {/* onClick이란 event라는 것인데 event에 대한 내용은 위에 참조하고
            onClick이벤트는 클릭했을 시 이벤트임 */}
            <H.admin_LoginBtn onClick={()=>{
              //버튼을 클릭했을 시 위에 있는 check_login 함수를 호출
              check_login();
              }}>
                로그인
            </H.admin_LoginBtn>
          </H.main_loginWrap>

        </div>
      </H.main_sectionBox>

      <Footer/>
    </>
  );
}

export default Login;
